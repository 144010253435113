// @flow
import { useCallback } from 'react';
import { useQueryLoader } from 'react-relay';
import type { FetchPolicy } from 'relay-runtime';

import NodeQuery from '../__generated__/NodeQuery.graphql';
import type { NodeQuery as NodeQueryType } from '../__generated__/NodeQuery.graphql';

export default function usePreloadNode(
  id: string,
  fetchPolicy: ?FetchPolicy = 'store-or-network',
): () => void {
  const [, loadNode] = useQueryLoader<NodeQueryType>(NodeQuery);

  const preload = useCallback(() => {
    loadNode(
      {
        id,
      },
      { fetchPolicy: fetchPolicy ?? undefined },
    );
  }, [loadNode, fetchPolicy, id]);

  return preload;
}
