// @flow
import { useEffect } from 'react';

export default function usePageView(pageTitle: string): void {
  useEffect(() => {
    document.title = `${pageTitle} - Serpa.cloud`;

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event: 'page_view' });
  }, [pageTitle]);
}
