// @flow
import type { LayoutSize } from './Flexbox';

type PropsGrid = {
  columns?: string,
  columnGap?: LayoutSize,
  rows?: string,
  rowGap?: LayoutSize,
  className?: string,
  children?: ?React$Node,
  alignContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'stretch'
    | '',
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | '',
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline' | '',
};

export default function Grid({
  rows,
  columns,
  children,
  className,
  rowGap = 8,
  columnGap = 8,
  alignItems = '',
  alignContent = '',
  justifyContent = '',
}: PropsGrid): React$Node {
  return (
    <div
      className={className}
      style={{
        alignItems,
        alignContent,
        justifyContent,
        display: 'grid',
        rowGap: `${rowGap}px`,
        gridTemplateRows: rows,
        columnGap: `${columnGap}px`,
        gridTemplateColumns: columns,
      }}
    >
      {children}
    </div>
  );
}

Grid.defaultProps = {
  alignContent: '',
  justifyContent: '',
  alignItems: '',
  columns: '',
  columnGap: 8,
  rows: '',
  rowGap: 8,
  className: '',
  children: null,
};
