// @flow
import React from 'react';
import stylex from '@ladifire-opensource/stylex';

import type { LayoutSize } from './Flexbox';

export type IconWeight = 100 | 200 | 300 | 400 | 500 | 600 | 700;
export type IconGrade = -25 | 0 | 200;
export type OpticalSize = 20 | 24 | 40 | 48;

export type IconColor =
  | 'primary'
  | 'secondary'
  | 'lite'
  | 'accent'
  | 'success'
  | 'successInverted'
  | 'warning'
  | 'error'
  | 'bulletAccent'
  | 'purple';

type Props = {|
  fill?: boolean,
  icon: string,
  size?: LayoutSize,
  className?: string,
  weight?: IconWeight,
  grade?: IconGrade,
  opticalSize?: OpticalSize,
  color?: IconColor,
  customColor?: ?string,
|};

const styles = stylex.create({
  primaryColor: {
    color: 'var(--primary-icon-color)',
  },

  secondaryColor: {
    color: 'var(--secondary-icon-color)',
  },

  liteColor: {
    color: 'var(--lite-icon-color)',
  },

  accentColor: {
    color: 'var(--accent)',
  },

  errorColor: {
    color: 'var(--error)',
  },

  bulletAccentColor: {
    color: 'var(--bullet-accent)',
  },

  successColor: {
    color: 'var(--success)',
  },

  successInvertedColor: {
    color: 'var(--success-inverted)',
  },

  warningColor: {
    color: 'var(--warning)',
  },

  purpleColor: {
    color: 'var(--purple)',
  },
});

function Icon({
  icon,
  fill,
  size = 24,
  weight = 200,
  grade = 200,
  opticalSize = 24,
  className = '',
  color = 'primary',
  customColor = '',
}: Props): React$Node {
  let colorClassName = null;

  if (!customColor) {
    if (color === 'primary') colorClassName = styles.primaryColor;
    if (color === 'secondary') colorClassName = styles.secondaryColor;
    if (color === 'lite') colorClassName = styles.liteColor;
    if (color === 'accent') colorClassName = styles.accentColor;
    if (color === 'error') colorClassName = styles.errorColor;
    if (color === 'bulletAccent') colorClassName = styles.bulletAccentColor;
    if (color === 'success') colorClassName = styles.successColor;
    if (color === 'warning') colorClassName = styles.warningColor;
    if (color === 'successInverted') colorClassName = styles.successInvertedColor;
    if (color === 'purple') colorClassName = styles.purpleColor;
  }

  const style = {
    fontSize: size,
    color: customColor,
    fontVariationSettings: `"FILL" ${
      fill ? 1 : 0
    }, "wght" ${weight}, "GRAD" ${grade}, "opsz" ${opticalSize}`,
  };

  return (
    <div style={{ width: size, height: size }} className={stylex(colorClassName)}>
      <i className={`material-symbols-outlined ${className}`} style={style}>
        {icon}
      </i>
    </div>
  );
}

Icon.defaultProps = {
  fill: false,
  size: 24,
  weight: 200,
  grade: 200,
  opticalSize: 24,
  className: '',
  color: 'primary',
  customColor: '',
};

export default (React.memo<Props>(Icon): React$AbstractComponent<Props, mixed>);
