// @flow
/* eslint-disable camelcase */

export default function sendCustomEvent(
  name?: ?string,
  category?: ?string,
  action?: ?string,
  label?: ?string,
  detail?: ?string,
): void {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'custom_event',
    custom_event_name: name || '',
    custom_event_category: category || '',
    custom_event_action: action || '',
    custom_event_label: label || '',
    custom_event_detail: detail || '',
  });
}
