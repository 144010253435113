// @flow
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import type { FetchPolicy } from 'relay-runtime';

const cache = new Map();

export default function useFetchPolicy(): FetchPolicy {
  const { pathname, search } = useLocation();
  const url = `${pathname}${search}`;
  const urlIsDifferent = url !== window.initialRenderedUrl;
  const [ref, setRef] = useState<FetchPolicy>(
    urlIsDifferent ? 'store-and-network' : 'store-or-network',
  );

  useEffect(() => {
    if (!urlIsDifferent) {
      const hasRendered = !!cache.get(url);

      if (hasRendered) setRef('store-and-network');
      else {
        cache.set(url, 1);
        setRef('store-or-network');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return ref;
}
