// @flow
import stylex from '@ladifire-opensource/stylex';
import { forwardRef, useImperativeHandle, useCallback } from 'react';

import Icon from './Icon';
import Flexbox from './Flexbox';
import InteractiveElement from './InteractiveElement';
import Input, { useInput, validateData } from './Input';

export type EnvironmentVariablesFieldsRef = {|
  +submit: () => { index: number, data: { name: string, value: string }, errors: any },
|};

const styles = stylex.create({
  container: {
    position: 'relative',
  },
  partialWidth: {
    width: 288,
  },
  iconContainer: {
    top: 16,
    width: 24,
    height: 24,
    right: -40,
    cursor: 'pointer',
    position: 'absolute',
  },
});

type Props = {|
  +index: number,
  +setRows: (any) => void,
  +defaultName?: ?string,
  +defaultValue?: ?string,
|};

function EnvironmentVariablesFieldsComponent(
  { index, setRows, defaultName, defaultValue }: Props,
  ref,
) {
  const name = useInput({
    name: 'name',
    value: defaultName ?? '',
    // eslint-disable-next-line no-useless-escape
    regexpOverwrite: /^[a-zA-Z0-9\-\_]*/,
    label: 'Nombre de la variable',
    required: true,
    errors: {
      requiredError: 'This field is required',
    },
  });

  const value = useInput({
    name: 'value',
    value: defaultValue ?? '',
    label: 'Valor de la variable',
    required: true,
    errors: {
      requiredError: 'This field is required',
    },
  });

  const handleOnDelete = useCallback(() => {
    setRows((o) => {
      const n = [...o];
      n.splice(index, 1);
      return n;
    });
  }, [index, setRows]);

  const handleOnSubmit = useCallback(() => {
    return validateData([name, value]);
  }, [name, value]);

  useImperativeHandle(ref, () => ({
    submit() {
      const { data, errors } = handleOnSubmit();
      return { index, data, errors };
    },
  }));

  return (
    <div className={stylex(styles.container)}>
      <Flexbox columnGap={16} justifyContent="space-between">
        <div className={stylex(styles.partialWidth)}>
          <Input input={name.input} />
        </div>
        <div className={stylex(styles.partialWidth)}>
          <Input input={value.input} />
        </div>
      </Flexbox>
      <InteractiveElement className={stylex(styles.iconContainer)} onClick={handleOnDelete}>
        <Icon icon="delete" size={24} opticalSize={24} />
      </InteractiveElement>
    </div>
  );
}

const EnvironmentVariablesFields: React$AbstractComponent<
  Props,
  EnvironmentVariablesFieldsRef,
> = forwardRef(EnvironmentVariablesFieldsComponent);

EnvironmentVariablesFieldsComponent.defaultProps = {
  defaultName: '',
  defaultValue: '',
};

export default EnvironmentVariablesFields;
