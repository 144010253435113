// @flow
import { cloneElement } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';

import type { FetchPolicy } from 'relay-runtime';

type Props = {|
  +id: string,
  +fetchKey?: ?number,
  +forceCache?: ?boolean,
  +fetchPolicy?: ?FetchPolicy,
  +children: React$Element<any>,
|};

export default function Node({
  id,
  fetchKey,
  fetchPolicy,
  forceCache,
  children,
}: Props): React$Node {
  const data = useLazyLoadQuery(
    graphql`
      query NodeQuery($id: ID!) {
        node(id: $id) {
          id
          ... on App {
            id
            ...AppDetail
          }
          ... on Environment {
            id
            ...EnvironmentDetail
          }
          ... on Release {
            id
            ...ReleaseDetail
          }
          ... on ReleaseChannel {
            id
            ...ReleaseChannelCreateDataInterface
          }
          ... on Implementation {
            id
            ...ImplementationDetail
          }
          ... on Build {
            id
            ...BuildDetail
          }
          ... on Deployment {
            id
            ...DeploymentDataInterface
          }
          ... on Site {
            id
            ...SiteDetail
            ...SiteCreateDataInterface
            ...SiteErrorPagesDataInterface
          }
        }
      }
    `,
    { id },
    {
      fetchPolicy: fetchPolicy ?? undefined,
      fetchKey: fetchKey ?? undefined,
      networkCacheConfig: { force: forceCache },
    },
  );

  return cloneElement(children, { node: data.node });
}

Node.defaultProps = {
  forceCache: false,
  fetchPolicy: 'store-and-network',
};
