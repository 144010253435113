// @flow
export type LayoutSize =
  | 0
  | 4
  | 8
  | 12
  | 16
  | 24
  | 32
  | 40
  | 48
  | 56
  | 64
  | 72
  | 80
  | 88
  | 96
  | 104
  | 112
  | 120
  | 128
  | 136
  | 144
  | 152
  | 160
  | 168
  | 176
  | 184
  | 192
  | 200;

type Props = {
  children?: ?React$Node,
  alignContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'stretch'
    | '',
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | '',
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline' | '',
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse' | '',
  flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse' | '',
  columnGap?: LayoutSize,
  rowGap?: LayoutSize,
  className?: string,
};

export default function Flexbox({
  children,
  className,
  flexWrap = '',
  alignItems = '',
  alignContent = '',
  flexDirection = '',
  justifyContent = '',
  columnGap = 0,
  rowGap = 0,
}: Props): React$Node {
  return (
    <div
      className={className}
      style={{
        display: 'flex',
        flexWrap,
        alignItems,
        alignContent,
        flexDirection,
        justifyContent,
        columnGap,
        rowGap,
      }}
    >
      {children}
    </div>
  );
}

Flexbox.defaultProps = {
  alignContent: '',
  justifyContent: '',
  alignItems: '',
  flexDirection: '',
  flexWrap: '',
  className: '',
  columnGap: 0,
  rowGap: 0,
  children: null,
};
