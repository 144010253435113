/* eslint-disable react/require-default-props */
/* eslint-disable react/no-array-index-key */
// @flow
import { forwardRef, useRef, useState, useImperativeHandle, useCallback } from 'react';

import Link from './Link';
import Flexbox from './Flexbox';
import TetraText from './TetraText';
import TooltipLegend from './TooltipLegend';
import Margin from './Margin';
import LiteButton from './LiteButton';

import EnvironmentVariablesFields from './EnvironmentVariablesFields';

export type EnvironmentVariablesFormRef = {|
  +submit: () => Array<{ index: number, data: { name: string, value: string }, errors: any }>,
|};

type Props = {|
  +description: string,
  +defaultValues?: ?$ReadOnlyArray<{|
    +name: string,
    +value: string,
  |}>,
|};

function EnvironmentVariablesFormComponent({ description, defaultValues }: Props, ref) {
  const fieldsRef = useRef([]);
  const [rows, setRows] = useState<
    Array<
      [
        number,
        {|
          +name: string,
          +value: string,
        |},
      ],
    >,
  >(defaultValues ? defaultValues.map((el, index) => [index, el]) : []);

  useImperativeHandle(ref, () => ({
    submit() {
      return fieldsRef?.current?.map((el) => el?.submit?.() ?? null).filter(Boolean);
    },
  }));

  const handleOnAddVariable = useCallback(() => {
    setRows((o) => [...o, [(o?.[o.length - 1]?.[0] ?? 0) + 1, { name: '', value: '' }]]);
  }, [setRows]);

  return (
    <>
      <Flexbox flexDirection="column" rowGap={16}>
        <TetraText type="label">Variables de entorno (opcional)</TetraText>
        <TooltipLegend>
          <div>{description}</div>
          <Flexbox alignItems="center" columnGap={4}>
            <span>Aprende más acerca de</span>
            <Link type="annotation" to="/">
              Precedencia de variables de entorno y sobreescritura
            </Link>
          </Flexbox>
        </TooltipLegend>
      </Flexbox>
      <Margin top={24}>
        <Flexbox flexDirection="column" rowGap={16}>
          {rows.map((row, index) => (
            <EnvironmentVariablesFields
              key={row[0]}
              index={index}
              setRows={setRows}
              defaultName={row[1].name}
              defaultValue={row[1].value}
              ref={(el) => {
                fieldsRef.current[index] = el;
              }}
            />
          ))}
        </Flexbox>
      </Margin>
      <Margin top={16}>
        <LiteButton icon="add" onClick={handleOnAddVariable}>
          Agregar variable
        </LiteButton>
      </Margin>
    </>
  );
}

const EnvironmentVariablesForm: React$AbstractComponent<
  Props,
  EnvironmentVariablesFormRef,
> = forwardRef(EnvironmentVariablesFormComponent);

export default EnvironmentVariablesForm;
