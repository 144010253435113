/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { RelayEnvironmentProvider } from 'react-relay';

import App from './App';

import RelayEnvironment from './RelayEnvironment';
import reportWebVitals from './reportWebVitals';

import { AppProvider } from './shared';

import './shared/styles.css';

hydrateRoot(
  document.getElementById('root'),
  <React.StrictMode>
    <RelayEnvironmentProvider environment={RelayEnvironment}>
      <BrowserRouter>
        <AppProvider>
          <App />
        </AppProvider>
      </BrowserRouter>
    </RelayEnvironmentProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
