import { graphql } from 'react-relay';

export default graphql`
  query SessionQuery {
    me {
      id
      name
      lastname
      media(width: 80, height: 80) {
        ...Avatar
      }
      hasOrganizations
    }
  }
`;
