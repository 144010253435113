// @flow

// Libs
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useLazyLoadQuery } from 'react-relay/hooks';

import SessionQuery from '../queries/Session';

import sendCustomEvent from './utils/sendCustomEvent';

type Props = {
  children: any,
  publicRoute?: boolean,
  redirectTo: string,
  fallback?: React$Node,
};

export default function SessionController({
  fallback,
  children,
  redirectTo,
  publicRoute,
}: Props): React$Node {
  const userData = useLazyLoadQuery(SessionQuery, {});

  const me = userData?.me || {};
  const isRegistered = !!me?.id;
  const mustRedirect = (publicRoute && isRegistered) || (!publicRoute && !isRegistered);

  React.useEffect(() => {
    if (mustRedirect) sendCustomEvent('component', 'session-controller', 'navigate', redirectTo);
  }, [mustRedirect, redirectTo]);

  if (mustRedirect) {
    return <Navigate to={redirectTo} />;
  }

  return <React.Suspense fallback={fallback || <div />}>{children}</React.Suspense>;
}

SessionController.defaultProps = {
  publicRoute: false,
  fallback: null,
};
