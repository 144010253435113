// @flow
import { animated } from 'react-spring';
import stylex from '@ladifire-opensource/stylex';

import Padding from './Padding';
import Notification from './Notification';

import useNotification from './hooks/useNotification';

const styles = stylex.create({
  container: {
    position: 'fixed',
    bottom: 16,
    right: 16,
    zIndex: 9,
  },
});

export default function Notifications(): React$Node {
  const [, transitions] = useNotification();

  return (
    <div className={stylex(styles.container)}>
      {transitions((style, item) => {
        return (
          <animated.div style={style}>
            <Padding top={24}>
              <Notification
                id={item.id}
                type={item.type}
                message={item.message}
                cancel={item.cancel}
              />
            </Padding>
          </animated.div>
        );
      })}
    </div>
  );
}
