// @flow
import { useCallback } from 'react';
import stylex from '@ladifire-opensource/stylex';
import { Link as CustomLink } from 'react-router-dom';

import type { LocationShape } from 'react-router-dom';

import TetraText from './TetraText';

import sendCustomEvent from './utils/sendCustomEvent';

type TetraTextType =
  | 'hero'
  | 'title'
  | 'subtitle'
  | 'head'
  | 'label'
  | 'section-label'
  | 'annotation'
  | 'note'
  | 'placeholder'
  | 'paragraph';

type Props = {
  +type?: TetraTextType,
  +children: React$Node,
  +className?: string,
  +to: string | LocationShape,
  +replace?: boolean,
  +onClick?: ?Function,
  ...
};

const styles = stylex.create({
  link: {
    textDecoration: 'none',
    ':hover': {
      textDecoration: 'underline',
      textDecorationColor: 'var(--accent)',
    },
  },
});

export default function Link({
  type = 'paragraph',
  children,
  onClick,
  ...props
}: Props): React$Node {
  const handleOnClick = useCallback(() => {
    sendCustomEvent('component', 'link', 'click');
    if (onClick) onClick();
  }, [onClick]);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <CustomLink {...props} className={stylex(styles.link)} onClick={handleOnClick}>
      <TetraText type={type} color="accent">
        {children}
      </TetraText>
    </CustomLink>
  );
}

Link.defaultProps = {
  type: 'paragraph',
  replace: false,
  className: undefined,
  onClick: undefined,
};
