// @flow
import stylex from '@ladifire-opensource/stylex';

import Card from './Card';
import Icon from './Icon';
import Padding from './Padding';
import Flexbox from './Flexbox';
import TapIcon from './TapIcon';
import TetraText from './TetraText';

import useNotification from './hooks/useNotification';

import sendCustomEvent from './utils/sendCustomEvent';

import type { Notification as NotificationType } from './AppProvider';

const styles = stylex.create({
  card: {
    minWidth: 320,
    maxWidth: 520,
  },
});

export default function Notification({ id, type, cancel, message }: NotificationType): React$Node {
  let icon = '';
  let color = 'success';

  if (type === 'SUCCESS') {
    icon = 'check_circle';
    color = 'success';
  } else if (type === 'WARNING') {
    icon = 'warning';
    color = 'warning';
  } else if (type === 'ERROR') {
    icon = 'error';
    color = 'error';
  }

  const [, , , dispose] = useNotification();

  return (
    <Card className={styles.card}>
      <Padding vertical={16} horizontal={16}>
        <Flexbox alignItems="center" justifyContent="space-between">
          <Padding right={24}>
            <Flexbox alignItems="center" columnGap={16}>
              <Icon fill icon={icon} color={color} />
              <TetraText type="paragraph">{message}</TetraText>
            </Flexbox>
          </Padding>
          <TapIcon
            icon="close"
            onClick={() => {
              sendCustomEvent('component', 'notification', 'click', 'close');
              cancel?.();
              dispose(id);
            }}
          />
        </Flexbox>
      </Padding>
    </Card>
  );
}
