// @flow
import React from 'react';
import stylex from '@ladifire-opensource/stylex';

import Flexbox from './Flexbox';
import TetraText from './TetraText';

import Icon from './Icon';
import InteractiveElement from './InteractiveElement';

import sendCustomEvent from './utils/sendCustomEvent';

import type { InteractiveElementEvent } from './InteractiveElement';

const styles = stylex.create({
  container: {
    height: 32,
    position: 'relative',
    borderRadius: 4,
    '-webkit-tap-highlight-color': 'transparent',
    '-webkit-user-select': 'none',
    transitionTimingFunction: 'var(--fds-animation-fade-out)',
    transitionDuration: 'var(--fds-duration-extra-extra-short-out)',
    transitionProperty: 'opacity',
    color: 'var(--lite-color-button)',
    fill: 'var(--lite-color-button)',
    backgroundColor: 'var(--lite-background-button)',
  },
  content: {
    width: '100%',
    height: '100%',
    borderRadius: 8,
    display: 'flex',
    outline: 'none',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    zIndex: 2,
    touchAction: 'manipulation',
  },
  textContainer: {
    paddingLeft: 24,
    paddingRight: 24,
    boxSizing: 'border-box',
    flexShrink: 0,
    flexWrap: 'nowrap',
  },
  enable: {
    opacity: 1,
  },
  disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
  },
  overlay: {
    backgroundColor: 'var(--hover-overlay)',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
    borderRadius: 4,
    width: '100%',
    height: '100%',
    transitionTimingFunction: 'var(--fds-animation-fade-out)',
    transitionDuration: 'var(--fds-duration-extra-extra-short-out)',
    transitionProperty: 'opacity',
  },
  unhover: {
    opacity: 0,
  },
  hover: {
    opacity: 1,
  },
});

type Props = {|
  +children: string,
  +icon?: ?string,
  +disabled?: boolean,
  +onClick: InteractiveElementEvent,
|};

function LiteButton({ icon, disabled, children, onClick }: Props): React$Node {
  const [hover, setHover] = React.useState<boolean>(false);

  const handleOnClick = React.useCallback(
    (e) => {
      if (onClick) onClick(e);
      sendCustomEvent('component', 'lite-button', 'click', children, icon ?? 'noicon');
    },
    [onClick, icon, children],
  );

  return (
    <div
      className={stylex(styles.container, disabled ? styles.disabled : styles.enable)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onFocus={() => setHover(true)}
      onBlur={() => setHover(false)}
    >
      <InteractiveElement
        label={children}
        onClick={onClick}
        onKeyPress={handleOnClick}
        disabled={disabled}
        className={stylex(styles.content)}
      >
        <Flexbox
          className={stylex(styles.textContainer)}
          justifyContent="center"
          alignItems="center"
          flexDirection="row"
          columnGap={8}
        >
          {icon && <Icon icon={icon} color="accent" size={16} weight={500} opticalSize={20} />}
          <TetraText type="section-label">{children}</TetraText>
        </Flexbox>
      </InteractiveElement>
      <div className={stylex(styles.overlay, hover ? styles.hover : styles.unhover)} />
    </div>
  );
}

LiteButton.defaultProps = {
  icon: null,
  disabled: false,
};

export default (React.memo<Props>(LiteButton): React$AbstractComponent<Props, mixed>);
