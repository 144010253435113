// @flow
import { useRef, useEffect } from 'react';
import stylex from '@ladifire-opensource/stylex';
import { useSpring, animated } from 'react-spring';

import InteractiveElement from './InteractiveElement';

import sendCustomEvent from './utils/sendCustomEvent';

const styles = stylex.create({
  animated: {
    overflow: 'hidden',
  },
});

type Props = {
  show: boolean,
  header: React$Node,
  children: React$Node,
  onChange: () => void,
};

export default function Accordeon({ show = false, header, children, onChange }: Props): React$Node {
  const ref = useRef(null);

  const [style, animate] = useSpring(
    () => ({
      height: `${show ? ref?.current?.offsetHeight ?? '0' : 0}px`,
      opacity: show ? 1 : 0,
    }),
    [],
  );

  useEffect(() => {
    animate.start({
      height: `${show ? ref?.current?.offsetHeight ?? '0' : 0}px`,
      opacity: show ? 1 : 0,
    });

    sendCustomEvent('component', 'accordeon', 'animate', Boolean(show).toString());
  }, [animate, ref, show]);

  return (
    <div>
      <InteractiveElement avoidAnimation onClick={onChange}>
        {header}
      </InteractiveElement>
      <animated.div style={style} className={stylex(styles.animated)}>
        <div ref={ref}>{children}</div>
      </animated.div>
    </div>
  );
}
