// @flow
import Icon from './Icon';
import Flexbox from './Flexbox';
import TetraText from './TetraText';

type Props = {
  children: React$Node,
};

export default function TooltipLegend({ children }: Props): React$Node {
  return (
    <Flexbox columnGap={8} alignItems="center">
      <Icon size={16} icon="info" fill color="lite" />
      <TetraText type="annotation">{children}</TetraText>
    </Flexbox>
  );
}
