/* eslint-disable no-nested-ternary */
// @flow
/* eslint-disable react/jsx-props-no-spreading */
// Libs
import * as React from 'react';
import { useIntl } from 'react-intl';
import stylex from '@ladifire-opensource/stylex';

import Icon from './Icon';
import Flexbox from './Flexbox';
import TetraText from './TetraText';

type ValidationInputEvent = 'change' | 'blur';

type Replacer<value> = (value) => string;

type CustomValidation<value> = (value) => boolean;

export type useInputErrors = {
  requiredError?: string | {| +id: string |},
  defaultError?: string | {| +id: string |},
  minlengthError?: string | {| +id: string |},
  maxlengthError?: string | {| +id: string |},
};

export type useInputProps = {|
  placeholder?: string,
  name: string,
  value?: string,
  errors?: useInputErrors,
  type?: string,
  spellCheck?: boolean,
  required?: boolean,
  +regexp?: ?string | ?RegExp,
  autoComplete?: boolean,
  +regexpOverwrite?: ?string | ?RegExp,
  label?: string | {| +id: string |},
  validateEvent?: ValidationInputEvent,
  toLowerCase?: boolean,
  toUpperCase?: boolean,
  +replacer?: ?Replacer<string>,
  +minlength?: ?number,
  +maxlength?: ?number,
  +customValidation?: ?CustomValidation<string>,
  disabled?: boolean,
  validateMinLengthOnBlur?: boolean,
  +maskRegex?: ?string | ?RegExp,
  +maskPermanents?: ?Array<number>,
  maskPermanentChar?: string,
  maskChar?: string,
  icon?: string,
  maskPrefill?: boolean,
  onChange?: ?(e: SyntheticInputEvent<HTMLInputElement>) => void,
|};

export type inputRef = {|
  disabled: boolean,
  error: any | string,
  onBlur: (e: SyntheticInputEvent<HTMLInputElement>) => void,
  onChange: (e: SyntheticInputEvent<HTMLInputElement>) => void,
  labelText: string,
  spellCheck: boolean,
  type: string,
  value: any | string,
  name: string,
  autoComplete: boolean,
  icon?: string,
  placeholder?: string,
|};

export type useInputRef = {|
  input: inputRef,
  setData: (((any) => any) | any) => void,
  type: string,
  validate: (config?: any) => boolean,
|};

function createErrors(fields: Array<useInputRef>): Array<useInputRef> {
  return fields
    .map((field) => {
      if (field?.input) return field.validate() ? field : null;
      return null;
    })
    .filter(Boolean);
}

function createData(fields: Array<useInputRef>): any {
  if (!fields || !fields.length) return null;

  const result = {};

  fields.forEach((field) => {
    let newValue = field.input.value;
    if (field.type === 'number') newValue = Number(field.input.value);
    if (typeof newValue === 'string') newValue = newValue.trim();

    const propertyPathArr = field.input.name.split('.');

    let reference = result;

    propertyPathArr.forEach((path, index, arr) => {
      const nextValue = arr[index + 1];
      const pathToNumber = Number(path);
      const pathIsNumber = !Number.isNaN(pathToNumber);
      const pathReference = pathIsNumber ? pathToNumber : path;

      if (!nextValue) {
        reference[pathReference] = newValue;
      } else {
        const nextPathToNumber = Number(nextValue);
        const nextPathIsNumber = !Number.isNaN(nextPathToNumber);
        if (nextPathIsNumber) {
          reference[pathReference] = [...(reference[pathReference] || [])];
        } else {
          reference[pathReference] = { ...(reference[pathReference] || {}) };
        }
        reference = reference[pathReference];
      }
    });
  });

  return result;
}

type ValidationResult<TParams> = {
  data: $Exact<TParams>,
  errors: void | Array<useInputRef>,
};

export function validateData<TParams>(fields: Array<useInputRef>): ValidationResult<TParams> {
  const errorsArray = createErrors(fields);
  const data = createData(fields);
  const errors = (errorsArray.length && errorsArray) || undefined;
  return { data, errors };
}

export function useInput({
  // Nombre del campo en el formulario
  name,
  // Valor inicial
  value,
  // Objeto de errores a mostrar incluye required, default y min, ver + abajo
  errors = {},
  // Tipo del input
  type = 'text',
  // Activar o desactivar correciones ortograficas
  spellCheck = false,
  autoComplete = false,
  // Marcar como requerido
  required = false,
  // Expresion para activar errores
  regexp = null,
  // Expresion para remplazar
  regexpOverwrite = null,
  label = '',
  // Evento en cual validar ya sea change o blur
  validateEvent = '',
  // Mandar todo a minuscula
  toLowerCase = false,
  // Mandar todo a mayuscula
  toUpperCase = false,
  /**
   * Funcion para hacer un replace custom ej:
   * (valor) => { return nuevoValor }
   */
  replacer = null,
  minlength = null,
  maxlength = null,
  /**
   * Funcion para hacer un validacion personalizada:
   * (valor) => { return esValido; }
   */
  customValidation = null,
  // Desactivar interaccion en el input
  disabled = false,
  // Forzar validacion de longitud en blur
  validateMinLengthOnBlur = false,
  icon = '',
  placeholder = '',
  onChange = null,
}: useInputProps): useInputRef {
  const intl = useIntl();

  const [data, setData] = React.useState({
    value: value || '',
    displayValue: value || '',
    error: '',
  });

  React.useEffect(() => {
    if (value)
      setData((oldState) => ({
        ...oldState,
        value: value || '',
        displayValue: value || '',
      }));
  }, [value]);

  const {
    requiredError = '',
    defaultError = '',
    minlengthError = '',
    maxlengthError = '',
  } = errors;

  const validate = (config: any = {}) => {
    const { avoidValidation = false } = config;
    let { value: inputValue } = data;
    let error = '';

    if (typeof inputValue === 'string') inputValue = inputValue.trim();

    if (required && !inputValue)
      error =
        typeof requiredError === 'string'
          ? requiredError
          : intl.formatMessage({ id: requiredError.id });
    else if (minlength && minlength > inputValue.length && !!inputValue.length)
      error =
        typeof minlengthError === 'string'
          ? minlengthError
          : intl.formatMessage({ id: minlengthError.id });
    else if (regexp && inputValue) {
      if (!new RegExp(regexp).test(inputValue))
        error =
          typeof defaultError === 'string'
            ? defaultError
            : intl.formatMessage({ id: defaultError.id });
    }
    if (customValidation) {
      if (!customValidation(inputValue))
        error =
          typeof defaultError === 'string'
            ? defaultError
            : intl.formatMessage({ id: defaultError.id });
    }
    if (!avoidValidation) setData((currentState) => ({ ...currentState, error }));
    return !!error;
  };

  const labelText: string =
    typeof label === 'string' ? label : intl.formatMessage({ id: label.id });

  const input = {
    name,
    disabled,
    spellCheck,
    labelText,
    type: type || 'text',
    autoComplete,
    icon,
    placeholder,
    onChange: (e: SyntheticInputEvent<HTMLInputElement>) => {
      let { value: targetValue } = e.target;
      let error = '';
      if (toLowerCase) targetValue = targetValue.toLowerCase();
      else if (toUpperCase) targetValue = targetValue.toUpperCase();

      if (maxlength && targetValue.length > maxlength) {
        targetValue = targetValue.substring(0, maxlength);
      }

      if (validateEvent === 'change') {
        if (maxlength && targetValue.length > maxlength) {
          error = maxlengthError;
        }
        if (required && !targetValue) error = requiredError;
        else if (regexp && targetValue) {
          if (!new RegExp(regexp).test(targetValue)) error = defaultError;
        }
        if (customValidation) {
          if (!customValidation(targetValue)) error = defaultError;
        }
      }

      if (regexpOverwrite) {
        targetValue = (targetValue.match(new RegExp(regexpOverwrite)) || []).join('');
      }

      const displayValue = targetValue;

      if (replacer) targetValue = replacer(targetValue);

      setData({ value: targetValue, displayValue, error });

      if (onChange) onChange(e);
    },
    onBlur: (e: SyntheticInputEvent<HTMLInputElement>) => {
      let { value: targetValue } = e.target;
      let error = '';

      if (validateEvent === 'blur') {
        if (maxlength && targetValue.length > maxlength) {
          targetValue = targetValue.substring(0, maxlength);
          error = maxlengthError;
        }
        if (required && !targetValue) error = requiredError;
        else if (minlength && minlength > targetValue.length) error = minlengthError;
        else if (regexp && targetValue) {
          if (!new RegExp(regexp).test(targetValue)) error = defaultError;
        }
        if (customValidation) {
          if (!customValidation(targetValue)) error = defaultError;
        }
        setData((state) => ({ ...state, value: targetValue, error }));
      } else if (validateMinLengthOnBlur) {
        if (minlength && minlength > targetValue.length) error = minlengthError;
        setData((state) => ({ ...state, value: targetValue, error }));
      }
    },
    value: data.value,
    error: data.error,
  };

  return {
    input,
    setData,
    validate,
    type,
  };
}

type InputType = {
  input: inputRef,
  +children?: ?React$Node,
  +iconComponent?: ?React$Node,
};

const styles = stylex.create({
  label: {
    zIndex: 0,
    height: 56,
    boxSizing: 'border-box',
    cursor: 'text',
    display: 'flex',
    outline: 'none',
    borderRadius: 8,
    position: 'relative',
    backgroundColor: 'var(--surface-background)',
  },
  labelFocus: {
    border: '1px solid var(--input-focus-border)',
  },
  labelBlur: {
    border: '1px solid var(--input-blur-border)',
  },
  labelError: {
    border: '1px solid var(--input-error-border)',
  },
  text: {
    left: 16,
    cursor: 'inherit',
    position: 'absolute',
    transitionDuration: 'var(--fds-fast)',
    pointerEvents: 'none',
    transitionProperty: 'transform',
    top: 21,
    transformOrigin: 'top left',
    maxWidth: '100%',
    transitionTimingFunction: 'var(--fds-soft)',
    right: 8,
    display: 'block',
  },
  textOpen: {
    transform: 'scale(.75) translateY(-17px)',
  },
  input: {
    fontSize: '1rem',
    paddingTop: 26,
    paddingRight: 16,
    paddingBottom: 10,
    paddingLeft: 16,
    color: 'var(--input-color)',
    '-webkit-tap-highlight-color': 'transparent',
    fontWeight: 'normal',
    width: '100%',
    backgroundColor: 'transparent',
    lineHeight: 1.25,
    touchAction: 'manipulation',
    border: 'none',
    outline: 'none',
  },
  select: {
    fontSize: '1rem',
    paddingTop: 26,
    paddingBottom: 10,
    paddingLeft: 12,
    color: 'var(--input-color)',
    '-webkit-tap-highlight-color': 'transparent',
    fontWeight: 'normal',
    width: 'calc(100% - 16px)',
    backgroundColor: 'transparent',
    lineHeight: 1.25,
    touchAction: 'manipulation',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
  },
  iconContainer: {
    position: 'absolute',
    top: 16,
    right: 16,
  },
});

export default function Input({
  children,
  iconComponent,
  input: {
    name,
    error,
    value,
    onBlur,
    onChange,
    labelText,
    type = 'text',
    disabled = false,
    spellCheck = false,
    autoComplete = false,
  },
}: InputType): React$Node {
  const [focus, setFocus] = React.useState(!!value);
  const avoidTextToOverlap = type === 'date' || !!children;

  const isOpen = focus || !!value;

  function handleFocus() {
    setFocus(true);
  }

  function handleBlur(e) {
    e.persist();

    setFocus(false);

    onBlur(e);
  }

  const overrideStyles = {};

  if (disabled) {
    overrideStyles.opacity = '0.4';
    overrideStyles.pointerEvents = 'none';
    overrideStyles.userSelect = 'none';
  }

  return (
    <Flexbox flexDirection="column" rowGap={8}>
      <label
        htmlFor={name}
        aria-label={labelText}
        style={overrideStyles}
        className={stylex(
          styles.label,
          error ? styles.labelError : isOpen ? styles.labelFocus : styles.labelBlur,
        )}
      >
        <span
          className={stylex(styles.text, avoidTextToOverlap || isOpen ? styles.textOpen : null)}
        >
          <TetraText type="placeholder" color={error ? 'error' : isOpen ? 'accent' : ''}>
            {labelText}
          </TetraText>
        </span>
        {!!error && type !== 'date' && (
          <div className={stylex(styles.iconContainer)}>
            <Icon icon="error" weight={500} color="error" fill />
          </div>
        )}
        {!!iconComponent && <div className={stylex(styles.iconContainer)}>{iconComponent}</div>}
        {!children && (
          <input
            id={name}
            dir="auto"
            type={type}
            value={value}
            onBlur={handleBlur}
            disabled={disabled}
            onChange={onChange}
            onFocus={handleFocus}
            aria-invalid={!!error}
            aria-label={labelText}
            spellCheck={spellCheck}
            className={stylex(styles.input)}
            autoComplete={autoComplete ? 'on' : 'off'}
          />
        )}
        {!!children && (
          <select
            id={name}
            dir="auto"
            type={type}
            value={value}
            onBlur={handleBlur}
            disabled={disabled}
            onChange={onChange}
            onFocus={handleFocus}
            aria-invalid={!!error}
            aria-label={labelText}
            spellCheck={spellCheck}
            className={stylex(styles.select)}
          >
            {children}
          </select>
        )}
      </label>
      {!!error && (
        <TetraText type="annotation" color="error">
          {error}
        </TetraText>
      )}
    </Flexbox>
  );
}

Input.defaultProps = {
  children: null,
  iconComponent: null,
};
