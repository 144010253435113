// @flow
import React from 'react';
import stylex from '@ladifire-opensource/stylex';

import Icon from './Icon';
import InteractiveElement from './InteractiveElement';

import sendCustomEvent from './utils/sendCustomEvent';

const styles = stylex.create({
  checkContainer: {
    width: 24,
    height: 24,
    borderRadius: 12,
    transitionProperty: 'all',
    transitionTimingFunction: 'var(--fds-soft)',
    transitionDuration: 'var(--fds-fast)',
    cursor: 'pointer',
    boxSizing: 'border-box',
  },
  unchecked: {
    border: '1px solid var(--checkbox-border)',
    backgroundColor: 'transparent',
  },
  checked: {
    border: '1px solid var(--accent)',
    backgroundColor: 'var(--accent)',
  },
  enabled: {
    opacity: 1,
  },
  disabled: {
    opacity: 0.6,
    pointerEvents: 'none',
  },
});

type Props = {
  checked: boolean,
  disabled?: boolean,
  onChange: (boolean) => void,
};
export default function RadioButton({ checked, onChange, disabled = false }: Props): React$Node {
  const handleOnChange = React.useCallback<(any) => void>(() => {
    if (onChange) onChange(!checked);
    sendCustomEvent('component', 'redio_button', 'change', Boolean(!checked).toString());
  }, [onChange, checked]);

  return (
    <InteractiveElement
      disabled={disabled}
      onClick={handleOnChange}
      className={stylex(
        styles.checkContainer,
        checked ? styles.checked : styles.unchecked,
        disabled ? styles.disabled : styles.enabled,
      )}
    >
      {checked ? <Icon icon="check" color="successInverted" /> : null}
    </InteractiveElement>
  );
}

RadioButton.defaultProps = {
  disabled: false,
};
