// @flow
import { useState, useCallback } from 'react';
import stylex from '@ladifire-opensource/stylex';

import Icon from './Icon';

const styles = stylex.create({
  container: {
    position: 'relative',
  },
  iconContainer: {
    position: 'absolute',
    top: 8,
    left: 8,
    width: 16,
    height: 16,
  },
  input: {
    width: '100%',
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 9,
    paddingBottom: 8,
    height: 32,
    minWidth: 272,
    textIndent: 24,
    border: 'none',
    outline: 'none',
    borderRadius: 4,
    boxSizing: 'border-box',
    backgroundColor: 'var(--fast-input-background)',
  },
});

type Props = {|
  +placeholder: string,
  +onChange: (value: string) => void,
|};

export default function FastSearchInput({ onChange, placeholder }: Props): React$Node {
  const [value, setValue] = useState<string>('');

  const handleOnChange = useCallback(
    (e: SyntheticEvent<HTMLInputElement>) => {
      const { value: newValue } = e.currentTarget;

      setValue(newValue);
      onChange(newValue);
    },
    [onChange],
  );

  return (
    <div className={stylex(styles.container)}>
      <div className={stylex(styles.iconContainer)}>
        <Icon icon="search" size={16} weight={300} />
      </div>
      <input
        value={value}
        onChange={handleOnChange}
        className={stylex(styles.input)}
        placeholder={placeholder}
      />
    </div>
  );
}
