// @flow
import { useTransition } from 'react-spring';
import { useContext, useCallback } from 'react';

import { AppContext } from '../AppProvider';
import type { Notification } from '../AppProvider';

const config = { tension: 125, friction: 20, precision: 0.1 };

export default function useNotification(): [
  Notification[],
  any,
  (Notification) => void,
  (string) => void,
] {
  const { state, dispatch } = useContext(AppContext);
  const { notifications } = state;

  const setNotification = useCallback(
    (newNotification: Notification) => {
      dispatch({ type: 'SET_NOTIFICATION', notification: newNotification });
    },
    [dispatch],
  );

  const disposeNotification = useCallback(
    (id: string) => {
      dispatch({ type: 'REMOVE_NOTIFICATION', notificationId: id });
    },
    [dispatch],
  );

  const transitions = useTransition(notifications, {
    from: { opacity: 0, y: 200, life: 100 },
    keys: (item: Notification) => item.id,
    enter: { opacity: 1, y: 0 },
    leave: { opacity: 0, y: 200 },
    config: (item, index, phase) => (key) =>
      phase === 'enter' && key === 'life' ? { duration: 5000 } : config,
  });

  return [notifications, transitions, setNotification, disposeNotification];
}
