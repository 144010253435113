// @flow
import stylex from '@ladifire-opensource/stylex';

export type CardProps = {
  className?: string,
  children?: React$Node,
};

const styles = stylex.create({
  container: {
    borderRadius: 8,
    boxShadow: 'var(--shadow-1-composite)',
    position: 'relative',
    zIndex: 0,
    backgroundColor: 'var(--surface-background)',
  },
});

export default function Card({ children, className = '' }: CardProps): React$Node {
  return <div className={`${stylex(styles.container)} ${className}`}>{children}</div>;
}

Card.defaultProps = {
  className: '',
  children: null,
};
