// @flow
import stylex from '@ladifire-opensource/stylex';
import { useSearchParams, NavLink, Link } from 'react-router-dom';

import Icon from './Icon';
import Avatar from './Avatar';
import Flexbox from './Flexbox';
import Padding from './Padding';
import TetraText from './TetraText';

import type { Avatar$key } from './__generated__/Avatar.graphql';

type LinkProp = {|
  +id: any,
  +path: string,
  +icon: string,
  +label: string,
|};

type Props = {|
  +title: string,
  +link: string,
  +links: Array<LinkProp>,
  +media: null | Avatar$key,
|};

const styles = stylex.create({
  header: {
    top: 56,
    zIndex: 9,
    position: 'sticky',
    background: 'var(--section-header-background)',
  },
  activeElement: {
    borderBottom: '2px solid var(--accent)',
  },
  inactiveElement: {
    borderBottom: '2px solid transparent',
  },
});

export default function DetailHeader({ title, media, link, links }: Props): React$Node {
  const [searchParams] = useSearchParams();
  const org = searchParams.get('org') ?? '0';

  return (
    <Padding className={stylex(styles.header)} horizontal={24}>
      <Padding top={24} bottom={24}>
        <Link to={`${link}?org=${org}`}>
          <Flexbox columnGap={16} alignItems="center">
            <Avatar node={media} fallbackIcon={<Icon icon="image" color="lite" />} />
            <TetraText type="subtitle">{title}</TetraText>
          </Flexbox>
        </Link>
      </Padding>
      <Flexbox columnGap={16}>
        {links.map((childLink) => (
          <NavLink key={childLink.id} to={`${childLink.path}?org=${org}`}>
            {/* $FlowIssue */}
            {({ isActive }) => (
              <Padding
                horizontal={16}
                bottom={8}
                className={stylex(isActive ? styles.activeElement : styles.inactiveElement)}
              >
                <Flexbox alignItems="center" columnGap={8}>
                  <Icon icon={childLink.icon} weight={200} color={isActive ? 'primary' : 'lite'} />
                  <TetraText type="label" color={isActive ? '' : 'secondary'}>
                    {childLink.label}
                  </TetraText>
                </Flexbox>
              </Padding>
            )}
          </NavLink>
        ))}
      </Flexbox>
    </Padding>
  );
}
