// @flow
import stylex from '@ladifire-opensource/stylex';

import type { LayoutSize } from './Flexbox';

import { ReactComponent as LogoSVG } from './iconAssets/logo.svg';

type Props = {
  size?: LayoutSize,
  className?: string,
};

const styles = stylex.create({
  logo: {
    fill: 'var(--logo-icon-color)',
  },
});

export default function Logo({ size, className }: Props): React$Node {
  return <LogoSVG width={size} className={stylex(styles.logo, className)} />;
}

Logo.defaultProps = {
  size: 96,
  className: '',
};
