/**
 * @generated SignedSource<<7e1018ffc8c9976c5aba604435f2849a>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment, RefetchableFragment } from 'relay-runtime';
type AccountElement$fragmentType = any;
type AppElement$fragmentType = any;
type AppSelectableElement$fragmentType = any;
type BuildElement$fragmentType = any;
type BuildListElement$fragmentType = any;
type DeploymentElement$fragmentType = any;
type DockerElement$fragmentType = any;
type EnvironmentElement$fragmentType = any;
type ImplementationElement$fragmentType = any;
type KubernetesElement$fragmentType = any;
type ReleaseChannelElement$fragmentType = any;
type ReleaseElement$fragmentType = any;
type SiteElement$fragmentType = any;
type SourceElement$fragmentType = any;
import type { FragmentType } from "relay-runtime";
declare export opaque type ScrolledListElement$fragmentType: FragmentType;
type ScrolledListPaginationQuery$variables = any;
export type ScrolledListElement$data = {|
  +entities: ?{|
    +pageInfo: ?{|
      +hasNextPage: ?boolean,
      +endCursor: ?any,
      +finalCursor: ?any,
    |},
    +edges: ?$ReadOnlyArray<?{|
      +id: string,
      +cursor: any,
      +node: {|
        +__typename: string,
        +id?: string,
        +$fragmentSpreads: SourceElement$fragmentType & DockerElement$fragmentType & AppElement$fragmentType & AppSelectableElement$fragmentType & BuildElement$fragmentType & BuildListElement$fragmentType & ReleaseElement$fragmentType & ReleaseChannelElement$fragmentType & EnvironmentElement$fragmentType & ImplementationElement$fragmentType & DeploymentElement$fragmentType & AccountElement$fragmentType & KubernetesElement$fragmentType & SiteElement$fragmentType,
      |},
    |}>,
  |},
  +$fragmentType: ScrolledListElement$fragmentType,
|};
export type ScrolledListElement$key = {
  +$data?: ScrolledListElement$data,
  +$fragmentSpreads: ScrolledListElement$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = [
  "entities"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "after"
    },
    {
      "kind": "RootArgument",
      "name": "filterMatrix"
    },
    {
      "kind": "RootArgument",
      "name": "first"
    },
    {
      "kind": "RootArgument",
      "name": "index"
    },
    {
      "kind": "RootArgument",
      "name": "orgIndex"
    },
    {
      "kind": "RootArgument",
      "name": "query"
    },
    {
      "kind": "RootArgument",
      "name": "sort"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./ScrolledListPaginationQuery.graphql')
    }
  },
  "name": "ScrolledListElement",
  "selections": [
    {
      "alias": "entities",
      "args": [
        {
          "kind": "Variable",
          "name": "filterMatrix",
          "variableName": "filterMatrix"
        },
        {
          "kind": "Variable",
          "name": "index",
          "variableName": "index"
        },
        {
          "kind": "Variable",
          "name": "orgIndex",
          "variableName": "orgIndex"
        },
        {
          "kind": "Variable",
          "name": "query",
          "variableName": "query"
        },
        {
          "kind": "Variable",
          "name": "sort",
          "variableName": "sort"
        }
      ],
      "concreteType": "EntitiesConnection",
      "kind": "LinkedField",
      "name": "__ScrolledList_root_entities_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "finalCursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Edge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v1/*: any*/),
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "DockerElement"
                    }
                  ],
                  "type": "DockerFile",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v1/*: any*/),
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "AppElement"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "AppSelectableElement"
                    }
                  ],
                  "type": "App",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v1/*: any*/),
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "BuildElement"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "BuildListElement"
                    }
                  ],
                  "type": "Build",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v1/*: any*/),
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "ReleaseElement"
                    }
                  ],
                  "type": "Release",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v1/*: any*/),
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "ReleaseChannelElement"
                    }
                  ],
                  "type": "ReleaseChannel",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v1/*: any*/),
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "EnvironmentElement"
                    }
                  ],
                  "type": "Environment",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v1/*: any*/),
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "ImplementationElement"
                    }
                  ],
                  "type": "Implementation",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v1/*: any*/),
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "DeploymentElement"
                    }
                  ],
                  "type": "Deployment",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v1/*: any*/)
                  ],
                  "type": "Artifact",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "AccountElement"
                    }
                  ],
                  "type": "CloudAccount",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "KubernetesElement"
                    }
                  ],
                  "type": "KubernetesCluster",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "SiteElement"
                    }
                  ],
                  "type": "Site",
                  "abstractKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "SourceElement"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "64138328ad5a5532b1fc35f32051f548";

module.exports = ((node/*: any*/)/*: RefetchableFragment<
  ScrolledListElement$fragmentType,
  ScrolledListElement$data,
  ScrolledListPaginationQuery$variables,
>*/);
