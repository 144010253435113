// @flow
import stylex from '@ladifire-opensource/stylex';
import { useState, useRef, useCallback } from 'react';

import Icon from './Icon';
import Spinner from './Spinner';
import Flexbox from './Flexbox';
import TapIcon from './TapIcon';

import useImageload from './hooks/useImageLoad';

import sendCustomEvent from './utils/sendCustomEvent';

import type { LayoutSize } from './Flexbox';

type Props = {|
  +alt?: ?string,
  +size?: ?LayoutSize,
  +defaultUrl?: ?string,
  +sizeIcon?: ?LayoutSize,
  +onChange: (id: string) => void,
|};

const styles = stylex.create({
  inputFile: {
    position: 'absolute',
    zIndex: 2,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0,
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    borderRadius: 200,
  },
  container: {
    borderRadius: 200,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    transitionProperty: 'all',
    boxShadow: 'none',
    position: 'relative',
  },
  containerUnhover: {
    transitionDuration: 'var(--fds-duration-medium-in)',
    transitionTimingFunction: 'var(--fds-animation-fade-in)',
    backgroundColor: 'var(--secondary-background-icon-button)',
  },
  containerHover: {
    transitionDuration: 'var(--fds-duration-medium-out)',
    transitionTimingFunction: 'var(--fds-animation-fade-out)',
    backgroundColor: 'var(--primary-background-icon-button)',
    boxShadow: 'var(--shadow-1-composite)',
  },
  previewContainer: {
    width: '100%',
    height: '100%',
    borderRadius: 200,
    position: 'relative',
  },
  previewImage: {
    width: '100%',
    height: '100%',
    borderRadius: 200,
    objectFit: 'cover',
  },
  previewOverlay: {
    width: '100%',
    height: '100%',
    borderRadius: 200,
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: 'var(--loading-overlay)',
  },
  spinnerOverlay: {
    width: '100%',
    height: '100%',
    borderRadius: 200,
    position: 'absolute',
    top: 0,
    left: 0,
  },
  tapContainer: {
    position: 'absolute',
    top: 0,
    right: -8,
    zIndex: 3,
  },
});

export default function ImageUploader({
  alt,
  size,
  sizeIcon,
  defaultUrl,
  onChange: handleOnChange,
}: Props): React$Node {
  const inputRef = useRef();
  const [hover, setHover] = useState<boolean>(false);
  const [, isPending, imageUrl, previewUrl, onChange] = useImageload(alt, (id) => {
    if (handleOnChange) handleOnChange(id);
  });

  const handleOnMouseEnter = useCallback(() => {
    setHover(true);
  }, [setHover]);

  const handleOnMouseLeave = useCallback(() => {
    setHover(false);
  }, [setHover]);

  const handleOnTapAction = useCallback(() => {
    inputRef?.current?.click();
    sendCustomEvent('component', 'image-uploader', 'click', 'tap-icon');
  }, [inputRef]);

  const handleOnInputClick = useCallback(() => {
    sendCustomEvent('component', 'image-uploader', 'click', 'input');
  }, []);

  const showReplaceLoader = !!imageUrl && !isPending;

  return (
    <div
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      style={{ width: size, height: size }}
      className={stylex(styles.container, hover ? styles.containerHover : styles.containerUnhover)}
    >
      {isPending ? (
        <div className={stylex(styles.previewContainer)} style={{ width: size, height: size }}>
          <img className={stylex(styles.previewImage)} src={previewUrl} alt={alt ?? ''} />
          <div className={stylex(styles.previewOverlay)} />

          <Flexbox
            alignItems="center"
            justifyContent="center"
            className={stylex(styles.spinnerOverlay)}
          >
            <Spinner size={sizeIcon ?? 48} />
          </Flexbox>
        </div>
      ) : (
        <>
          <input
            ref={inputRef}
            type="file"
            name="upload"
            accept="image/*"
            onChange={onChange}
            onClick={handleOnInputClick}
            className={stylex(styles.inputFile)}
          />
          {imageUrl || defaultUrl ? (
            <img
              className={stylex(styles.previewImage)}
              src={imageUrl || defaultUrl}
              alt={alt ?? ''}
            />
          ) : (
            <Icon icon="add_photo_alternate" size={sizeIcon ?? 48} color="accent" />
          )}
          {showReplaceLoader && (
            <div className={stylex(styles.tapContainer)}>
              <TapIcon icon="add_photo_alternate" onClick={handleOnTapAction} />
            </div>
          )}
        </>
      )}
    </div>
  );
}

ImageUploader.defaultProps = {
  alt: '',
  size: 160,
  sizeIcon: 48,
  defaultUrl: null,
};
