// @flow
import Lottie from 'lottie-react';
import { useSearchParams } from 'react-router-dom';
import { useMemo, cloneElement, useCallback } from 'react';
import { graphql, usePaginationFragment, useLazyLoadQuery } from 'react-relay';

import type { FetchPolicy } from 'relay-runtime';

import emptyStateAnimationData from './emptyStateAnimationData.json';

import type { ScrolledListElement$key } from './__generated__/ScrolledListElement.graphql';

import ScrolledListPaginationQuery from './__generated__/ScrolledListPaginationQuery.graphql';

import type {
  SortInput,
  QueryInput,
  FilterInput,
  ElasticIndex,
  ScrolledListPaginationQuery as QueryType,
} from './__generated__/ScrolledListPaginationQuery.graphql';

import Margin from './Margin';
import Flexbox from './Flexbox';
import TetraText from './TetraText';
import LiteButton from './LiteButton';

type Props = {|
  +next?: ?number,
  +first?: ?number,
  +sort?: ?SortInput,
  +index: ElasticIndex,
  +query?: ?QueryInput,
  +fetchPolicy?: ?FetchPolicy,
  +emptyElement?: ?React$Node,
  +container?: ?React$Element<any>,
  +renderElement: (any, any) => React$Node,
  +filterMatrix?: ?$ReadOnlyArray<?$ReadOnlyArray<?FilterInput>>,
|};

export default function ScrolledList({
  next,
  sort,
  index,
  query,
  first,
  container,
  fetchPolicy,
  filterMatrix,
  emptyElement,
  renderElement,
}: Props): React$Node {
  const [searchParams] = useSearchParams();
  const org = searchParams.get('org') ?? '0';

  const node: ScrolledListElement$key = useLazyLoadQuery(
    ScrolledListPaginationQuery,
    { sort, index, first, query, filterMatrix, orgIndex: Number(org) },
    { fetchPolicy: fetchPolicy ?? undefined, networkCacheConfig: { force: false } },
  );

  const { data, loadNext, hasNext, isLoadingNext } = usePaginationFragment<QueryType, _>(
    graphql`
      fragment ScrolledListElement on Query @refetchable(queryName: "ScrolledListPaginationQuery") {
        entities(
          sort: $sort
          first: $first
          after: $after
          index: $index
          query: $query
          orgIndex: $orgIndex
          filterMatrix: $filterMatrix
        ) @connection(key: "ScrolledList_root_entities") {
          pageInfo {
            hasNextPage
            endCursor
            finalCursor
          }
          edges {
            id
            cursor
            node {
              __typename
              ... on DockerFile {
                id
                ...DockerElement
              }
              ... on App {
                id
                ...AppElement
                ...AppSelectableElement
              }
              ... on Build {
                id
                ...BuildElement
                ...BuildListElement
              }
              ... on Release {
                id
                ...ReleaseElement
              }
              ... on ReleaseChannel {
                id
                ...ReleaseChannelElement
              }
              ... on Environment {
                id
                ...EnvironmentElement
              }
              ... on Implementation {
                id
                ...ImplementationElement
              }
              ... on Deployment {
                id
                ...DeploymentElement
              }
              ... on Artifact {
                id
              }
              ... on CloudAccount {
                ...AccountElement
              }
              ... on KubernetesCluster {
                ...KubernetesElement
              }
              ... on Site {
                ...SiteElement
              }
              ...SourceElement
            }
          }
        }
      }
    `,
    node,
  );

  const edges = data?.entities?.edges;

  const elements = useMemo(() => {
    return edges?.map((edge) => renderElement(edge?.node, edge?.id));
  }, [edges, renderElement]);

  const handleOnLoad = useCallback(() => {
    if (hasNext && !isLoadingNext) {
      loadNext(next ?? 9);
    }
  }, [hasNext, isLoadingNext, loadNext, next]);

  if (!elements?.length)
    return (
      emptyElement ?? (
        <Flexbox flexDirection="column" alignItems="center" rowGap={8}>
          <Lottie animationData={emptyStateAnimationData} style={{ width: 240 }} loop={false} />
          <Margin bottom={16}>
            <Flexbox flexDirection="column" alignItems="center" rowGap={8}>
              <TetraText type="label">Oops todavía no hay nada por aquí</TetraText>
              <TetraText type="label">Tratemos de crear algo increíble el día de hoy</TetraText>
            </Flexbox>
          </Margin>
        </Flexbox>
      )
    );

  if (container)
    return (
      <Flexbox rowGap={24} flexDirection="column">
        <div>{cloneElement(container, { children: <>{elements}</> })}</div>
        {hasNext && (
          <div>
            <LiteButton
              onClick={handleOnLoad}
              icon={isLoadingNext ? 'hourglass_top' : 'rotate_right'}
            >
              Cargar más elementos
            </LiteButton>
          </div>
        )}
      </Flexbox>
    );

  return <>{elements}</>;
}

ScrolledList.defaultProps = {
  next: 9,
  first: 9,
  sort: null,
  query: null,
  emptyElement: null,
  filterMatrix: null,
  container: (<div />: React$Element<'div'>),
  fetchPolicy: 'store-and-network',
};
