// @flow
import React from 'react';
import { useIntl } from 'react-intl';
import stylex from '@ladifire-opensource/stylex';

import Flexbox from './Flexbox';
import TetraText from './TetraText';

import Icon from './Icon';
import InteractiveElement from './InteractiveElement';

import sendCustomEvent from './utils/sendCustomEvent';

import type { InteractiveElementEvent } from './InteractiveElement';

const styles = stylex.create({
  container: {
    height: 40,
    position: 'relative',
    borderRadius: 4,
    '-webkit-tap-highlight-color': 'transparent',
    '-webkit-user-select': 'none',
    transitionTimingFunction: 'var(--fds-animation-fade-out)',
    transitionDuration: 'var(--fds-duration-extra-extra-short-out)',
    transitionProperty: 'opacity',
  },
  content: {
    width: '100%',
    height: '100%',
    borderRadius: 8,
    display: 'flex',
    outline: 'none',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    zIndex: 2,
    touchAction: 'manipulation',
  },
  textContainer: {
    paddingLeft: 24,
    paddingRight: 24,
    boxSizing: 'border-box',
    flexShrink: 0,
    flexWrap: 'nowrap',
  },
  primary: {
    color: 'var(--primary-color-button)',
    fill: 'var(--primary-color-button)',
    backgroundColor: 'var(--primary-background-button)',
  },
  secondary: {
    color: 'var(--secondary-color-button)',
    fill: 'var(--secondary-color-button)',
    backgroundColor: 'var(--secondary-background-button)',
  },
  enable: {
    opacity: 1,
  },
  disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
  },
  overlay: {
    backgroundColor: 'var(--hover-overlay)',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
    borderRadius: 4,
    width: '100%',
    height: '100%',
    transitionTimingFunction: 'var(--fds-animation-fade-out)',
    transitionDuration: 'var(--fds-duration-extra-extra-short-out)',
    transitionProperty: 'opacity',
  },
  unhover: {
    opacity: 0,
  },
  hover: {
    opacity: 1,
  },
});

export type ButtonType = 'primary' | 'secondary';

type Props = {|
  +intlId?: ?string,
  +children?: ?string,
  +icon?: ?string,
  +disabled?: boolean,
  +buttonType?: ButtonType,
  +onClick: InteractiveElementEvent,
|};

function Button({
  icon,
  intlId,
  disabled,
  children,
  onClick,
  buttonType = 'primary',
}: Props): React$Node {
  const intl = useIntl();
  const [hover, setHover] = React.useState<boolean>(false);

  const handleOnClick = React.useCallback(
    (e) => {
      if (onClick) onClick(e);
      sendCustomEvent(
        'component',
        'button',
        'click',
        children ?? intlId,
        `${buttonType}-${icon ?? 'noicon'}`,
      );
    },
    [onClick, buttonType, icon, children, intlId],
  );

  return (
    <div
      className={stylex(
        styles.container,
        styles[buttonType],
        disabled ? styles.disabled : styles.enable,
      )}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onFocus={() => setHover(true)}
      onBlur={() => setHover(false)}
    >
      <InteractiveElement
        label={intlId ? intl.formatMessage({ id: intlId }) : children ?? ''}
        onClick={onClick}
        onKeyPress={handleOnClick}
        disabled={disabled}
        className={stylex(styles.content)}
      >
        <Flexbox
          className={stylex(styles.textContainer)}
          justifyContent="center"
          alignItems="center"
          flexDirection="row"
          columnGap={8}
        >
          {icon && <Icon icon={icon} customColor={`var(--${buttonType}-color-button)`} />}
          {intlId ? (
            <TetraText type="button" intlId={intlId} />
          ) : (
            <TetraText type="button">{children}</TetraText>
          )}
        </Flexbox>
      </InteractiveElement>
      <div className={stylex(styles.overlay, hover ? styles.hover : styles.unhover)} />
    </div>
  );
}

Button.defaultProps = {
  icon: null,
  disabled: false,
  buttonType: 'primary',
  intlId: null,
  children: null,
};

export default (React.memo<Props>(Button): React$AbstractComponent<Props, mixed>);
