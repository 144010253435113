import { Suspense, lazy } from 'react';
import { IntlProvider } from 'react-intl';
import stylex from '@ladifire-opensource/stylex';
import { Routes, Route } from 'react-router-dom';

import { ErrorBoundary, Spinner, SessionController, Flexbox } from './shared';

import spanishTranslations from './translations/spanish.json';
import englishTranslations from './translations/english.json';

const styles = stylex.create({
  loading: {
    width: '100vw',
    height: '100vh',
    backgroundColor: 'var(--surface-background)',
  },
});

const Session = lazy(() => import('./views/Session'));
const Dashboard = lazy(() => import('./views/Dashboard'));
const LandingPage = lazy(() => import('./views/LandingPage'));
const Organizations = lazy(() => import('./views/Organizations'));
const CreateOrgForm = lazy(() => import('./views/Organizations/CreateOrgForm'));

function App() {
  const loadingElement = (
    <Flexbox className={stylex(styles.loading)} alignItems="center" justifyContent="center">
      <Spinner size={40} />
    </Flexbox>
  );

  const locale = 'es';
  let translations = spanishTranslations;

  if (locale === 'en') {
    translations = englishTranslations;
  }

  return (
    <IntlProvider messages={translations} locale="es">
      <main>
        <ErrorBoundary>
          <Routes>
            <Route
              path="session/*"
              element={
                <Suspense fallback={loadingElement}>
                  <SessionController publicRoute redirectTo="/dashboard" fallback={loadingElement}>
                    <Session />
                  </SessionController>
                </Suspense>
              }
            />
            <Route
              path="dashboard/*"
              element={
                <Suspense fallback={loadingElement}>
                  <SessionController redirectTo="/session/signin" fallback={loadingElement}>
                    <Dashboard />
                  </SessionController>
                </Suspense>
              }
            />
            <Route
              path="organizations/create-new-organization"
              element={
                <Suspense fallback={loadingElement}>
                  <SessionController redirectTo="/session/signin" fallback={loadingElement}>
                    <CreateOrgForm />
                  </SessionController>
                </Suspense>
              }
            />
            <Route
              path="organizations"
              element={
                <Suspense fallback={loadingElement}>
                  <SessionController redirectTo="/session/signin" fallback={loadingElement}>
                    <Organizations />
                  </SessionController>
                </Suspense>
              }
            />
            <Route
              exact
              path="/"
              element={
                <Suspense fallback={loadingElement}>
                  <LandingPage />
                </Suspense>
              }
            />
          </Routes>
        </ErrorBoundary>
      </main>
    </IntlProvider>
  );
}

export default App;
